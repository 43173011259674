import React, { useState } from 'react';
import { toast } from 'react-hot-toast';

const AddCategories = () => {
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();

    const title = event.target.title.value;
    const profileImage = event.target.image.files[0];

    setIsLoading(true);

    // Image processing with ImageBB
    const formData = new FormData();
    formData.append('image', profileImage);

    const url = `https://api.imgbb.com/1/upload?&key=c76efd57d012df5b882d44b0f8f3394f`;
    fetch(url, {
      method: 'POST',
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        const categorie = {
          title: title,
          image: data.data.display_url,
        };
        // save category information to the database
        fetch('https://project-one-server-ezazahmed16.vercel.app/categories', {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
          },
          body: JSON.stringify(categorie),
        })
          .then((res) => res.json())
          .then((result) => {
            console.log(result);
            toast.success(`${categorie.title} is added successfully`);
            setIsLoading(false);
          })
          .catch((error) => {
            console.log(error);
            setIsLoading(false);
            toast.error(`Something went wrong.`);
          });
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        toast.error(`Something went wrong.`);
      });
  };

  return (
    <div>
      <div className="block mx-auto bg-blue-900 text-white py-3">
        <h1 className="text-center text-2xl font-semibold">Add Categories</h1>
      </div>
      <div className="px-5">
        <form
          onSubmit={handleSubmit}
          noValidate=""
          action=""
          className="space-y-12 ng-untouched ng-pristine ng-valid"
        >
          <div className="space-y-4">
            <div>
              <label htmlFor="email" className="block mb-2 text-sm">
                Title
              </label>
              <input
                type="text"
                name="title"
                id="title"
                required
                placeholder="Enter Your Category Title"
                className="w-full px-3 py-2 border rounded-md border-gray-300 focus:outline-green-500 bg-gray-200 text-gray-900"
                data-temp-mail-org="0"
              />
            </div>

            <div>
              <label htmlFor="image" className="block mb-2 text-sm">
                Select Image:
              </label>
              <input
                className="file-input file-input-bordered w-full max-w-xs"
                type="file"
                id="image"
                name="image"
                accept="image/*"
                required
              />
            </div>
          </div>
          <div className="space-y-2">
            <div>
              {isLoading ? (
                <button className="btn btn-primary" disabled>
                  <i className="fa fa-spinner fa-spin mr-2"></i> Adding Category...
                </button>
              ) : (
                <input className="btn btn-primary" type="submit" value="Add Category" />
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddCategories;