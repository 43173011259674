import React, { useState } from 'react'
import { toast } from 'react-hot-toast';

const Product = ({ product }) => {
    const { product_name, product_description, product_features, product_prices, product_images } = product;
    const [isLoading, setIsLoading] = useState(null)

    const handleDeleteProduct = product => {
        setIsLoading(true)
        fetch(`https://project-one-server-ezazahmed16.vercel.app/products/${product._id}`, {
            method: 'DELETE',
            headers: {
                authorization: `bearer ${localStorage.getItem('accessToken')}`
            }
        })
            .then(res => res.json())
            .then(data => {
                if (data.deletedCount > 0) {
                    toast.success(`${product_name} deleted successfully`)
                    setIsLoading(false)
                }
            })
        }

    if (isLoading) {
        return <div className="flex items-center justify-center space-x-2 my-5">
            <div className="w-4 h-4 rounded-full animate-pulse dark:bg-violet-400"></div>
            <div className="w-4 h-4 rounded-full animate-pulse dark:bg-violet-400"></div>
            <div className="w-4 h-4 rounded-full animate-pulse dark:bg-violet-400"></div>
        </div>
    }
    return (
        <tr>
            <td>
                <div className="flex items-center space-x-3">
                    <div className="mask mask-squircle w-16 h-16">
                        <img src={product_images} className='h-full w-full' alt="Avatar Tailwind CSS Component" />
                    </div>
                </div>
            </td>

            <td className='font-bold'>{product_name}</td>

            <td>
                <label onClick={() => handleDeleteProduct(product)} htmlFor="confirmation-modal" className="btn btn-sm btn-error">Delete</label>
            </td>
        </tr>
    )
}

export default Product