import React from 'react'
import Header from '../pages/shared/Header/Header'
import { Outlet } from 'react-router-dom'
import DashboardCategories from '../pages/Dashboard/DashboardCategories'

const DashboardLayout = () => {
    return (
        <div>
            <div>
                <Header></Header>
                {
                    <div className="grid grid-cols-4 my-4 m-3">
                        <div className="">
                            <DashboardCategories></DashboardCategories>
                        </div>
                        <div className="col-span-3 mx-5">
                            <Outlet></Outlet>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default DashboardLayout