import React, { useEffect, useState, } from 'react'
import AreaSlider from './AreaSlider';

const BusinessArea = () => {
    const [showAllObjects, setShowAllObjects] = useState(false);
    const [categories, setCategories] = useState([]);
    useEffect(() => {
        fetch('https://project-one-server-ezazahmed16.vercel.app/categories')
            .then(res => res.json())
            .then(data => setCategories(data))
    }, [])
    console.log(categories)
    const objectsToShow = showAllObjects ? categories : categories.slice(0, 3);


    return (
        <div className='mt-10 max-w-screen-xl m-auto'>
            <div className="divider"></div>
            <h1 style={{ color: '#2E3094' }} className='text-center font-extrabold text-3xl'>BUSINESS AREA</h1>
            <div className="divider"></div>


            <div className="grid sm:grid-cols-1 md:grid-cols-3 gap-5 md:gap-10">
                {
                    objectsToShow.map(categorie => <AreaSlider
                        key={categorie._id}
                        categorie={categorie}
                    ></AreaSlider>)
                }
            </div>
            {!showAllObjects && (
                <button className='btn btn-outline btn-primary block mx-auto my-5 drop-shadow-2xl' onClick={() => setShowAllObjects(true)}>See All</button>
            )}
        </div>
    )
}

export default BusinessArea