import React from 'react'
import { Link } from 'react-router-dom'

const DashboardCategories = () => {
    return (
        <div>
            <h1 className='p-5 bg-primary text-white text-center font-semibold text-2xl mb-3'>Dashboard</h1>
            <div className="">
                <Link to='/dashboard/addcategories'>
                    <div className="btn btn-outline btn-primary w-full my-1 grid">Add Categories</div>
                </Link>
                <Link to='/dashboard/allcategories'>
                    <div className="btn btn-outline btn-primary w-full grid my-1">All Categories</div>
                </Link>
                <Link to='/dashboard/addproducts'>
                    <div className="btn btn-outline btn-primary w-full grid my-1">Add Products</div>
                </Link>
                <Link to='/dashboard/allproducts'>
                    <div className="btn btn-outline btn-primary w-full grid my-1">All Products</div>
                </Link>
            </div>
        </div>
    )
}

export default DashboardCategories