import React from 'react'
import { useLoaderData } from 'react-router-dom'
import DetailsProduct from '../DetailsProduct/DetailsProduct';


const Product = () => {
  const product = useLoaderData();
  if (product.length <= 0) {
    return <h1 className='text-center m-2 p-2 font-semibold text-2xl'>Sorry, No products are available at this time</h1>
  }
  return (
    <div className="mx-3">
      <div className=" grid grid-cols-1 lg:grid-cols-3 gap-3">
        {
          product.map(p => <DetailsProduct
            key={p._id}
            p={p}
          ></DetailsProduct>)
        }
      </div>

    </div>
  )
}

export default Product