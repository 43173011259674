import { createBrowserRouter } from "react-router-dom";
import Main from "../layout/Main";
import Home from "../pages/home/Home";
import Contact from "../pages/Contact/Contact";
import Product from "../pages/Products/Product/Product";
import Dashboard from "../pages/Dashboard/Dashboard";
import ProductLayout from "../layout/ProductLayout";
import SingleProductDetails from "../pages/Products/SingleProductDetails/SingleProductDetails";
import Error from "../pages/Error/Error";
import Login from "../pages/Login/Login";
import DashboardLayout from "../layout/DashboardLayout";
import AddCategories from "../pages/Dashboard/AddCategories";
import AllCategories from "../pages/Dashboard/AllCategories";
import AllProducts from "../pages/Dashboard/AllProducts";
import AddProducts from "../pages/Dashboard/AddProducts";
import PrivateRoute from "./PrivateRoute";
import AdminRoute from "./AdminRoute";
import AboutUs from "../pages/AboutUs/AboutUs";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Main></Main>,
        errorElement: <Error></Error>,
        children: [
            {
                path: "/",
                element: <Home></Home>,
            },
            {
                path: "/home",
                element: <Home></Home>,
            },
            {
                path: "/contact",
                element: <Contact></Contact>,
            },
            {
                path: "/aboutus",
                element: <AboutUs></AboutUs>,
            },
            {
                path: "/login",
                element: <Login></Login>,
            }
        ]
    },

    {
        path: '/products',
        element: <ProductLayout></ProductLayout>,
        errorElement: <Error></Error>,
        children: [
            {    
                path: "/products",
                element: <Product></Product>,
                loader: () => fetch(`https://project-one-server-ezazahmed16.vercel.app/products`)
            },
            {    
                path: "/products/:title",
                element: <Product></Product>,
                loader: ({params}) => fetch(`https://project-one-server-ezazahmed16.vercel.app/products/${params.title}`)
            },
            {
                path: "/products/product/:id",
                element: <SingleProductDetails></SingleProductDetails>,
                loader: ({params}) => fetch(`https://project-one-server-ezazahmed16.vercel.app/products/product/${params.id}`)
            },
        ]
    },

    {
        path: '/dashboard',
        element: <PrivateRoute><DashboardLayout></DashboardLayout></PrivateRoute>,
        errorElement: <Error></Error>,
        children: [
            {    
                path: "/dashboard",
                element: <Dashboard></Dashboard>,
            },
            {    
                path: "/dashboard/addcategories",
                element: <AdminRoute><AddCategories></AddCategories></AdminRoute>,
            },
            {    
                path: "/dashboard/allcategories",
                element: <AdminRoute><AllCategories></AllCategories></AdminRoute>
            },
            {    
                path: "/dashboard/allproducts",
                element: <AdminRoute><AllProducts></AllProducts></AdminRoute>
            },
            {    
                path: "/dashboard/addProducts",
                element: <AdminRoute><AddProducts></AddProducts></AdminRoute>,
                loader: () => fetch(`https://project-one-server-ezazahmed16.vercel.app/categories`)
            },
            
        ]
    }

]);

export default router