import React from 'react'
import { Link } from 'react-router-dom';


const AreaSlider = ({ categorie }) => {
    // console.log(categorie)
    const { title, image } = categorie;
    return (
        <Link to={`/products/${title}`}>
            <div className='mb-5 drop-shadow-2xl '>
                <div className="card card-compact w-80 h-72 md:w-96 md:h-80 shadow-xl p-2 mx-auto">
                    <figure><img className='h-52 w-64 md:h-56 md:w-72' src={image} alt="Shoes" /></figure>
                    <div className="card-body mx-auto mt-2">
                        <h2 style={{
                            color: '#2E3094'
                        }} className="card-title font-bold">{title}</h2>
                    </div>
                </div>
            </div>
        </Link >
    )
}

export default AreaSlider