import React, { createContext, useEffect, useState } from 'react'
import { GoogleAuthProvider, getAuth, onAuthStateChanged, signInWithPopup, signOut } from "firebase/auth"
import app from '../firebase/Firebase.config'

export const AuthContext = createContext();
const auth = getAuth(app)
const provider = new GoogleAuthProvider();
const googleProvider = new GoogleAuthProvider();


const AuthProvidor = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true)
    const [currentUser, setCurrentUser] = useState({})

    //Google Login
    const googleLogin = () => {
        return signInWithPopup(auth, googleProvider)
    }
    //Logout
    const logOut = () =>{
        setLoading(true);
        return signOut(auth);
    }

    useEffect(() => {
        const unSubscribe = onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser)
            setLoading(false)
        })
        return () => {
            unSubscribe()
        }
    }, [])

    const authInfo = {
        user,
        loading,
        googleLogin,
        logOut
    }

    return (
        <AuthContext.Provider value={authInfo}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthProvidor