import React, { } from 'react'
import { NavLink } from 'react-router-dom'

const Categorie = ({ categorie }) => {
    return (
        <div className=''>
            <div className="m-1">
                <NavLink to={`/products/${categorie.title}`}>
                    <button className='btn btn-outline btn-primary m-1 w-full'>{categorie.title}</button>
                </NavLink>

            </div>
        </div>
    )
}

export default Categorie