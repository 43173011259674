import React, { useState } from 'react'
import { useLoaderData } from 'react-router-dom'

const SingleProductDetails = () => {
  const productDetails = useLoaderData();
  const { product_name, product_images, product_description, product_features, product_prices } = productDetails;
  const [mainImage, setMainImage] = useState(product_images[0])

  console.log(product_images)

  return (
    <div>
      <div className="card bg-base-100 shadow-xl mx-5 mt-5">
        <div className="product_image grid items-center md:grid-cols-4">
          <div className="col-span-3">
            <figure><img className='w-96 h-96' src={mainImage} alt="" /></figure>
            {/* <figure><img className='w-auto h-96' src={product_images} alt="" /></figure> */}
          </div>

          <div className="flex justify-center md:grid md:grid-row-3 gap-4 p-2 border border-blue-950">
            {
              product_images?.map((curElm, i) => {
                return (
                  <figure>
                    <img src={curElm} alt="" className="w-40 h-32 box-content cursor-pointer	" key={i} onClick={() => setMainImage(curElm)} />
                  </figure>
                )
              })
            }
          </div>

        </div>

        <div className="card-body">
          <h2 className="card-title font-semibold bg-green-700 text-white p-2 px-4 mx-auto">{product_name}</h2>
          {
            product_prices ?
              <div className="">
                <p className='text-xl'>Price: <span className='font-bold text-xl'>{product_prices}</span> TK</p>
              </div>
              :
              <></>
          }
          {
            product_description ?
              <div className="">
                <h1 className='text-xl font-bold'>Description:</h1>
                <p>{product_description}</p>
              </div>
              :
              <></>
          }
          {
            product_features ?
              <div className="">
                <h1 className='text-xl font-bold'>Features:</h1>
                <p>{product_features}</p>
              </div>
              :
              <></>
          }

        </div>
      </div>
    </div>
  )
}

export default SingleProductDetails