import React from 'react'
import {Helmet} from "react-helmet";

const AboutUs = () => {
  return (
    <div className='min-h-screen pt-16'>
      <Helmet>
        <title>ONETOUCH MEDI SOLUTION | About Us</title>
      </Helmet>
      <h1 className='text-2xl text-center'>Comming Soon....</h1>
    </div>
  )
}

export default AboutUs