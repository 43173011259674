import React, { useEffect, useState } from 'react'
import Product from './Product';

const AllProducts = () => {
    const [products, setProducts] = useState([]);

    useEffect(() => {
        fetch(`https://project-one-server-ezazahmed16.vercel.app/products`)
            .then(res => res.json())
            .then(data => setProducts(data))
    }, [products])


    return (
        <div>
            <div className="block mx-auto bg-blue-900 text-white py-3">
                <h1 className='text-center text-2xl font-semibold'>All Products</h1>
            </div>
            <div className="">
                <div className="overflow-x-auto w-full">
                    <table className="table w-full">
                        {/* head */}
                        <thead>
                            <tr>
                                <th>Image</th>
                                <th>Title</th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* row  */}
                            {
                                products.map(product => <Product
                                    key={product}
                                    product={product}
                                ></Product>)
                            }

                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default AllProducts