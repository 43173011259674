import React from 'react'
import Banner from './Banner/Banner'
import AboutCompany from './AboutCompany/AboutCompany'
import BusinessArea from './BusinessArea/BusinessArea'
import {Helmet} from "react-helmet";

const Home = () => {
  return (
    <div>
      <Helmet>
        <title>ONETOUCH MEDI SOLUTION | Home</title>
      </Helmet>
      <Banner />
      <AboutCompany />
      <BusinessArea />
    </div>
  )
}

export default Home