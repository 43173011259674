import React from 'react'
import './Banner.css'
import img1 from '../../../assets/Banner/1.jpg'
import img2 from '../../../assets/Banner/2.jpg'
import img3 from '../../../assets/Banner/3.jpg'
import BannerItem from './BannerItem'


const BannerData = [
    {
        image: img1,
        prev: 3,
        id: 1,
        next: 2
    },
    {
        image: img2,
        prev: 1,
        id: 2,
        next: 3
    },
    {
        image: img3,
        prev: 2,
        id: 3,
        next: 1
    },
]

const Banner = () => {
    return (
        <div>
            <div className="carousel w-full h-96 lg:h-screen my-1 rounded-lg">
                {
                    BannerData.map(slide => <BannerItem
                        key={slide.id}
                        slide={slide}
                    ></BannerItem>)
                }
            </div>
        </div>
    )
}

export default Banner