import React, { useEffect, useState } from 'react'
import Categorie from './Categorie';

const AllCategories = () => {
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        fetch(`https://project-one-server-ezazahmed16.vercel.app/categories`)
            .then(res => res.json())
            .then(data => setCategories(data))
    }, [categories])

    // const categories = useLoaderData();

    return (
        <div>
            <div className="block mx-auto bg-blue-900 text-white py-3">
                <h1 className='text-center text-2xl font-semibold'>All Categories</h1>
            </div>
            <div className="">
                <div className="overflow-x-auto w-full">
                    <table className="table w-full">
                        {/* head */}
                        <thead>
                            <tr>
                                <th>Image</th>
                                <th>Title</th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* row  */}
                            {
                                categories.map(categorie => <Categorie
                                    key={categorie._id}
                                    categorie={categorie}
                                ></Categorie>)
                            }

                        </tbody>

                    </table>
                </div>
            </div>
        </div>
    )
}

export default AllCategories