import React, { useEffect, useState } from 'react'
import Categorie from './Categorie/Categorie';
import {Helmet} from "react-helmet";

const Products = () => {
  const [categories, allCategories] = useState([]);
  useEffect(() => {
    fetch('https://project-one-server-ezazahmed16.vercel.app/categories')
      .then(res => res.json())
      .then(data => allCategories(data))
  }, [categories]);

  return (
    <div className="md:min-h-screen">
      <Helmet>
        <title>OTMS || Products</title>
      </Helmet>
      <div className="">
        <h1 style={{ backgroundColor: '#2E3094' }} className='text-center text-2xl font-bold py-4 text-white p-1 ms-2'>Categories</h1>
      </div>

      <div className="grid lg:grid-cols-1 md:grid-cols-1 grid-cols-3">
        {
          categories.map(categorie => <Categorie
            key={categorie._id}
            categorie={categorie}
          ></Categorie>)
        }
      </div>
    </div>
  )
}

export default Products