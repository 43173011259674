import React from 'react'
import { Helmet } from "react-helmet";

const Dashboard = () => {
    return (
        <div className='max-w-screen-xl block m-auto'>
            <Helmet>
                <title>ONETOUCH MEDI SOLUTION
                    || Dashboard</title>
            </Helmet>
            <h1 className='text-center text-2xl pt-5 p-2 bg-blue-950 text-white'>Welcome</h1>
        </div>
    )
}

export default Dashboard