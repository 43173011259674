import React from 'react';
import Logo from '../../../assets/Logo/logo.jpg';
import './AboutCompany.css';

const AboutCompany = () => {
    return (
        <div className="my-5 lg:mt-5 mt-4">
            <div className="divider"></div>
            <h1 className="text-center font-extrabold text-2xl md:text-3xl title_color">ABOUT OUR COMPANY</h1>
            <div className="divider"></div>

            <div className="flex flex-col sm:flex-row md:max-w-5xl mx-auto gap-2 items-center">
                <div className="col-span-2">
                    <p className="text-sm md:text-xl text-justify whitespace-normal overflow-hidden title_color mx-2 md:mx-0">
                        ONETOUCH MEDI SOLUTION is the leading edge business conglomerate and the fastest growing marketing and distribution Company on Health Care Division in Bangladesh since 2016. Working as the distributor for some of the world-renowned brands in Bangladesh, we provide specialized Turnkey solution in the fields OT, ICU, CCU, NICU, Diagnostic, Laboratory, Hospital Bed & Furniture, Medical Gas Pipeline System, Medical Accessories Consumables.
                    </p>
                </div>
                <div className="mt-5 md:mt-0">
                    <div className="avatar block">
                        <div className="w-56 md:w-72 m-auto md:m-24 border rounded-2xl shadow-2xl">
                            <img className="p-6" alt="" src={Logo} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutCompany;
