import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyC_W1-hgvoE498-LZFXSWgRAmnzeuEfDLE",
  authDomain: "otmsbd.firebaseapp.com",
  projectId: "otmsbd",
  storageBucket: "otmsbd.appspot.com",
  messagingSenderId: "145461839859",
  appId: "1:145461839859:web:3412cc73f88d6115163486",
  measurementId: "G-DGM25YCZRC"
};

const app = initializeApp(firebaseConfig);

export default app;