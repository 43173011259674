import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import { useLoaderData } from 'react-router-dom';
import axios from 'axios';

const AddProducts = () => {
    const categories = useLoaderData();

    const [isLoading, setIsLoading] = useState(false);
    const [productName, setProductName] = useState('');
    const [productDescription, setProductDescription] = useState('');
    const [productFeatures, setProductFeatures] = useState('');
    const [productPrices, setProductPrices] = useState('');
    const [title, setTitle] = useState('');
    const [productImages, setProductImages] = useState([]);

    const handleImageUpload = async (files) => {
        if (files.length === 0) {
          return []; // No files selected, return an empty array
        }
      
        try {
          const imageUrls = await Promise.all(
            Array.from(files).map(async (file) => {
              const formData = new FormData();
              formData.append('image', file);
      
              const response = await axios.post(
                'https://api.imgbb.com/1/upload?&key=c76efd57d012df5b882d44b0f8f3394f',
                formData,
                {
                  headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                  },
                }
              );
              return response.data.data.url;
            })
          );
      
          console.log(imageUrls); // Check if imageUrls array is populated
      
          return imageUrls; // Return the image URLs
        } catch (error) {
          console.error(error);
          return []; // Return an empty array in case of error
        }
      };
      

    const handleSubmit = async () => {
        setIsLoading(true);

        const imageUrls = await handleImageUpload(productImages);


        const productData = {
            product_name: productName,
            product_description: productDescription,
            product_features: productFeatures,
            product_prices: productPrices,
            title: title,
            product_images: imageUrls,
        };

        try {
            await axios.post('https://project-one-server-ezazahmed16.vercel.app/products', productData);

            setIsLoading(false);
            toast.success(`${productName} is successfully added`);
        } catch (error) {
            console.error(error);
            setIsLoading(false);
            toast.error(`${productName} Something went wrong.`);
        }
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        if (
            !productName ||
            !productDescription ||
            !title ||
            productImages.length < 0 || productImages.length > 3
        ) {
            toast.error('Please fill in all required fields and select at least one image and maximum 3 image.');
            return;
        }

        try {
            setIsLoading(true);

            const imageUrls = await handleImageUpload(productImages);

            if (imageUrls.length === 0) {
                toast.error('Failed to upload images. Please try again.');
                setIsLoading(false);
                return;
            }

            const productData = {
                product_name: productName,
                product_description: productDescription,
                product_features: productFeatures,
                product_prices: productPrices,
                title: title,
                product_images: imageUrls,
            };

            await axios.post('https://project-one-server-ezazahmed16.vercel.app/products', productData);

            setIsLoading(false);
            toast.success(`${productName} is successfully added`);
        } catch (error) {
            console.error(error);
            setIsLoading(false);
            toast.error(`${productName} Something went wrong.`);
        }
    };

    return (
        <div>
            <div className="block mx-auto bg-blue-900 text-white py-3">
                <h1 className="text-center text-2xl font-semibold">Add Products</h1>
            </div>

            <div className="px-5">
                <form
                    onSubmit={handleFormSubmit}
                    noValidate=""
                    action=""
                    className="space-y-12 ng-untouched ng-pristine ng-valid"
                >
                    <div className="space-y-4">
                        {/* product Name */}
                        <div>
                            <label htmlFor="name" className="block mb-2 text-sm">
                                Product Name
                            </label>
                            <input
                                type="text"
                                name="name"
                                id="name"
                                required
                                placeholder="Enter Your Product Name"
                                className="w-full px-3 py-2 border rounded-md border-gray-300 focus:outline-green-500 bg-gray-200 text-gray-900"
                                data-temp-mail-org="0"
                                onChange={(e) => setProductName(e.target.value)}
                            />
                        </div>
                        {/* product description */}
                        <div>
                            <label htmlFor="description" className="block mb-2 text-sm">
                                Product Description
                            </label>
                            <textarea
                                type="text"
                                name="description"
                                id="description"
                                placeholder="Enter Your Product Description"
                                className="textarea textarea-primary border rounded-md border-gray-300 focus:outline-green-500 bg-gray-200 text-gray-900 textarea-lg w-full max-w-full whitespace-pre-line"
                                data-temp-mail-org="0"
                                onChange={(e) => setProductDescription(e.target.value)}
                            />
                        </div>
                        {/* product features */}
                        <div>
                            <label htmlFor="features" className="block mb-2 text-sm">
                                Product Features
                            </label>
                            <textarea
                                type="text"
                                name="features"
                                id="features"
                                placeholder="Enter Your Product Features"
                                className="textarea textarea-primary border rounded-md border-gray-300 focus:outline-green-500 bg-gray-200 text-gray-900 textarea-lg w-full max-w-full"
                                data-temp-mail-org="0"
                                onChange={(e) => setProductFeatures(e.target.value)}
                            />
                        </div>

                        <div className="grid grid-cols-2 gap-4">
                            {/* price */}
                            <div>
                                <label htmlFor="prices" className="block mb-2 text-sm">
                                    Product Price
                                </label>
                                <input
                                    type="text"
                                    name="prices"
                                    id="prices"
                                    placeholder="Enter Your Product Price"
                                    className="w-full px-3 py-2 border rounded-md border-gray-300 focus:outline-green-500 bg-gray-200 text-gray-900"
                                    data-temp-mail-org="0"
                                    onChange={(e) => setProductPrices(e.target.value)}
                                />
                            </div>

                            {/* images */}
                            <div>
                                <label htmlFor="images" className="block mb-2 text-sm">
                                    Select Image:
                                </label>

                                <input
                                    type="file"
                                    id="images"
                                    name="images"
                                    accept="image/*"
                                    className='file-input file-input-bordered w-full max-w-xs'
                                    required
                                    multiple
                                    onChange={(event) => setProductImages(event.target.files)}
                                />
                            </div>
                        </div>

                        {/* product categories */}
                        <label htmlFor="mySelect" className="block mb-2 text-sm">
                            Select Categories:
                        </label>
                        <select
                            className="select select-primary w-full max-w-xs"
                            name="mySelect"
                            id="mySelect"
                            onChange={(e) => setTitle(e.target.value)}
                        >
                            {categories &&
                                categories.map((c) => (
                                    <option key={c._id}>{c.title}</option>
                                ))}
                        </select>
                    </div>
                    <div className="space-y-2">
                        <div>
                            {isLoading ? (
                                <button className="btn btn-primary" disabled>
                                    <i className="fa fa-spinner fa-spin mr-2"></i> Adding Product...
                                </button>
                            ) : (
                                <input className="btn btn-primary" type="submit" value="Add Product" />
                            )}
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddProducts;