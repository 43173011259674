import React, { useRef, useState } from 'react';
import { EnvelopeIcon, MapPinIcon, PhoneIcon } from '@heroicons/react/24/solid'
import emailjs from '@emailjs/browser';
import toast from 'react-hot-toast';
import { Helmet } from "react-helmet";
import './Contact.css'

const Contact = () => {
    const [isLoading, setLoading] = useState(Boolean)
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_g8x15yn', 'template_b5mnhch', form.current, '5HOmgzW_hNjWveE5y')
            .then((result) => {
                toast.success('Successfully Send!')
                setLoading(false)
            }, (error) => {
                toast.error("Something is wrong. Please try again.");
                console.log(error)
                setLoading(false)
            });

        setLoading(true)
    };

    return (
        <>
            <div className="divider"></div>
            <div className='max-w-screen-xl m-auto block my-2'>
                <Helmet>
                    <title>OTMS || Contact Us</title>
                </Helmet>
                <div className="hero ">
                    <div className="hero-content grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 w-full">
                        <div className="py-3 md:p-6 text-center">

                            <h1 className="text-5xl font-bold title_color">Contact Us</h1>
                            <div className="divider"></div>

                            <div className="container w-5/6 md:w-3/5 m-auto block">
                                <div className="flex justify-start items-center my-2">
                                    <div className="mr-2">
                                        <EnvelopeIcon className="h-8 w-8 title_color mr-2" />
                                    </div>
                                    <div className="">
                                        <p className="title_color">E-mail: info.otms.bd@gmail.com</p>
                                    </div>
                                </div>

                                <div className="flex justify-start items-center my-2">
                                    <div className="mr-2">
                                        <PhoneIcon className="h-8 w-8 title_color" />
                                    </div>
                                    <div className="">
                                        <a href="tel:+8801713191041" className="title_color">Number: 01713191041, </a>
                                        <a href="tel:+8801713164292" className="title_color"> 01713164292</a>
                                    </div>
                                </div>

                                <div className="flex justify-start items-center my-2">
                                    <div className="mr-2">
                                        <MapPinIcon className="h-8 w-8 title_color" />
                                    </div>
                                    <div className="">
                                        <a className='title_color' href="https://www.google.com/maps?q=23.729856004900064, 90.41387655993444" target="_blank">Address: 23/3, Topkhana Road, Dhaka-1000. Bangladesh.</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card flex-shrink-0 w-full max-w-sm shadow-2xl mx-auto">
                            <form ref={form} onSubmit={sendEmail} className="card-body">
                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text title_color text-lg font-bold">Name</span>
                                    </label>
                                    <input required type="text" name="user_name" placeholder="Your Name" className="input input-bordered" />
                                </div>
                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text title_color text-lg font-bold">Email</span>
                                    </label>
                                    <input required type="email" name="user_email" placeholder="Your Email" className="input input-bordered" />
                                </div>
                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text title_color text-lg font-bold">Message</span>
                                    </label>
                                    <textarea required type="text" name="user_message" placeholder="Your Message" className="textarea textarea-bordered" />
                                </div>
                                {
                                    isLoading ? <div className="form-control mt-6">
                                        <input type="submit" value="Sending..." className="btn btn_color" />
                                    </div>
                                        :
                                        <div className="form-control mt-6">
                                            <input type="submit" value="Send" className="btn btn_color" />
                                        </div>
                                }
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Contact