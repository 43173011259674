import React from 'react'
import logo from '../../../assets/Logo/logo.png'
import { Link } from 'react-router-dom';
import './Footer.css'
import { AiOutlineMail } from 'react-icons/ai';


const Footer = () => {
    return (
        <div>
            <div className="divider"></div>
            <footer className="footer p-5 justify-around items-center">
                <div>
                    <img className='w-24 mx-auto' src={logo} alt="" />
                    <p className='text-center'>
                        <span className='font-semibold title'>
                            ONETOUCH MEDI SOLUTION
                        </span>
                        <br />
                        <span className=''>23/3, Topkhana Road, Dhaka-1000. Bangladesh.</span>
                    </p>
                </div>
                <div className='mx-auto md:mx-0 text-center'>
                    <span className="footer-title">Menu</span>
                    <Link to='/home'>Home</Link>
                    <Link to='/products'>Products</Link>
                    <Link to='/contact'>Contact</Link>
                </div>
                <div className='mx-auto md:mx-0 text-center'>
                    <span className="footer-title">Crafted by</span>
                    <p>Steadfast IT Solution & Development</p>
                    <p className='text-center flex items-center'> <AiOutlineMail className='h-6 w-6 mr-2' /> itsteadfast@gmail.com</p>
                </div>
            </footer>
            <div className="divider"></div>
        </div>
    )
}

export default Footer