import React, { useContext } from 'react'
import { ArrowRightCircleIcon } from '@heroicons/react/24/solid'
import { AuthContext } from '../../context/AuthProvidor';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import {Helmet} from "react-helmet";

const Login = () => {
    const { googleLogin, user } = useContext(AuthContext);
    const navigate = useNavigate();

    const handleGoogleLogin = () => {
        googleLogin()
            .then((result) => {
                toast.success('Login Success');
                // const admin = {
                //     name: result.user.displayName,
                //     email: result.user.email
                // }
                // fetch('https://project-one-server-ezazahmed16.vercel.app/users', {
                //     method: 'POST',
                //     headers: {
                //         'content-type': 'application/json',
                //     },
                //     body: JSON.stringify(admin)
                // })
                // .then(res => res.json())
                // .then(result => {
                //     navigate('/dashboard')
                // })
                navigate('/dashboard')
            }).catch((error) => {
                toast.error('Something is wrong. Please try again.')
            });
    }

    if (user) {
        navigate('/dashboard')
    }

    return (
        <div>
            <Helmet>
                <title>ONETOUCH MEDI SOLUTION
                    || Login</title>
            </Helmet>
            <div className="flex flex-col max-w-md mx-auto my-5 p-6 rounded-md sm:p-10 bg-blue-700 text-gray-100">
                <div className="mb-8 text-center">
                    <h1 className="my-3 text-4xl font-bold">Sign in</h1>
                    <p className="text-sm text-gray-400">Only for Admin</p>
                </div>
                <form noValidate="" action="" className="space-y-12 ng-untouched ng-pristine ng-valid">
                    <div className="space-y-2">
                        <div onClick={handleGoogleLogin}>
                            <button type="button" className="w-full px-8 py-3 font-semibold rounded-md btn btn-success text-white btn-outline">
                                <ArrowRightCircleIcon className="h-6 w-6 text-white mx-2" />

                                Google Singin</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Login