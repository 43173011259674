import React, { useContext } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { AuthContext } from '../../../context/AuthProvidor';
import navLogo from '../../../assets/Logo/navLogo.png';
import './Header.css';

const Header = () => {
    const { user, logOut } = useContext(AuthContext);

    const handleLogout = () => {
        logOut();
    };

    return (
        <div className="navbar bg-white justify-between">
            <div className="navbar-start w-full justify-between md:justify-start">
                <div className="dropdown">
                    <label tabIndex={0} className="btn btn-ghost lg:hidden">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" />
                        </svg>
                    </label>

                    <ul tabIndex={0} className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52">
                        <li className="font-semibold">
                            <NavLink className='menu_color text-black' to="/home">Home</NavLink>
                        </li>
                        <li className="font-semibold">
                            <NavLink className='menu_color text-black' to="/products">Products</NavLink>
                        </li>
                        <li className="font-semibold">
                            <NavLink className='menu_color text-black' to="/contact">Contact</NavLink>
                        </li>
                        {user ? (
                            <li onClick={handleLogout} className="text-black font-semibold btn btn-accent mx-2">
                                LogOut
                            </li>
                        ) : null}
                    </ul>
                </div>

                <Link to="/" className="normal-case text-xl lg:ml-3 ml-2">
                    <div className="flex items-center">
                        <img className="w-20 h-20 mx-6" src={navLogo} alt="" />
                        <p className="text-3xl bg-logo font-semibold">OTMS</p>
                    </div>
                </Link>
            </div>

            <div className="navbar-end mr-3 hidden lg:flex">
                <ul className="menu menu-horizontal px-1">
                    <li className="text-black font-semibold">
                        <NavLink className='menu_color text-black' to="/home">Home</NavLink>
                    </li>
                    <li className="text-black font-semibold">
                        <NavLink className='menu_color text-black' to="/products">Products</NavLink>
                    </li>
                    <li className="text-black font-semibold">
                        <NavLink className='menu_color text-black' to="/contact">Contact</NavLink>
                    </li>
                    {user ? (
                        <li onClick={handleLogout} className="text-black font-semibold btn btn-accent mx-2">
                            LogOut
                        </li>
                    ) : null}
                </ul>
            </div>

        </div>
    );
};

export default Header;
