import React from 'react'
import { EyeIcon } from '@heroicons/react/24/solid'
import { Link } from 'react-router-dom'


const DetailsProduct = ({ p }) => {
    const { product_name, product_images, _id } = p;

    return (
        <div>

            <div style={{ backgroundColor: '#2E3094' }} className="rounded-lg shadow-md text-white">

                <div className="container">
                    <img src={product_images} alt="" className="object-cover object-center w-full rounded-t-md h-72 dark:bg-gray-500" />
                </div>

                <div className="flex flex-col justify-between p-6 space-y-8">
                    <div className="">
                        <h2 className="text-sm font-semibold tracking-wide text-center h-12">{product_name}</h2>
                    </div>
                    <Link to={`/products/product/${_id}`}>
                        <button type="button" className="rounded-md btn btn-outline btn-secondary font-bold m-auto flex">
                            <EyeIcon className="h-6 w-6 mx-2 text-white" />
                            View</button>
                    </Link>
                </div>
            </div>

        </div>
    )
}

export default DetailsProduct