import React, { useState } from 'react';
import { toast } from 'react-hot-toast';

const Categorie = ({ categorie }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleDeleteCategorie = () => {
    setIsLoading(true);

    fetch(`https://project-one-server-ezazahmed16.vercel.app/categories/${categorie._id}`, {
      method: 'DELETE',
      headers: {
        authorization: `bearer ${localStorage.getItem('accessToken')}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.deletedCount > 0) {
          toast.success(`${categorie.title} deleted successfully`);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        toast.error('Something went wrong.');
      });
  };

  if (isLoading) {
    return (
      <tr>
        <td colSpan="3" className="text-center">
          <div className="flex items-center justify-center space-x-2">
            <div className="w-4 h-4 rounded-full animate-pulse dark:bg-violet-400"></div>
            <div className="w-4 h-4 rounded-full animate-pulse dark:bg-violet-400"></div>
            <div className="w-4 h-4 rounded-full animate-pulse dark:bg-violet-400"></div>
          </div>
        </td>
      </tr>
    );
  }

  return (
    <tr>
      <td>
        <div className="flex items-center space-x-3 my-2">
          <div className="mask mask-squircle w-16 h-16">
            <img src={categorie.image} className="h-full" alt="Avatar Tailwind CSS Component" />
          </div>
        </div>
      </td>

      <td className="font-bold">{categorie.title}</td>

      <td>
        <label onClick={handleDeleteCategorie} htmlFor="confirmation-modal" className="btn btn-sm btn-error">
          Delete
        </label>
      </td>
    </tr>
  );
};

export default Categorie;