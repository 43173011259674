import React, { useContext } from 'react';
import { AuthContext } from '../context/AuthProvidor';
import useAdmin from '../hooks/useAdmin';

const AdminRoute = ({ children }) => {
    const { user, loading } = useContext(AuthContext);
    const [isAdmin, isAdminLoading] = useAdmin(user?.email);

    if (loading || isAdminLoading) {
        return <h1>Loading</h1>
    }

    if (user && isAdmin) {
        return children;
    }

    return <h1 className='text-center m-2 p-2 font-semibold text-2xl'>Admin Access Only</h1>;
};

export default AdminRoute;