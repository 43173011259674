import React from 'react'
import { Link } from 'react-router-dom';

const BannerItem = ({ slide }) => {
    const { image, next, prev, id } = slide;
    return (
        <div id={`slide${id}`} className="carousel-item relative w-full">
            <div className="carousel-img h-full w-full">
                <img src={image} alt='banner' className="h-full w-full" />
            </div>
            
            <div className="absolute flex justify-between  left-5 right-5 top-1/2">
                <a href={`#slide${prev}`} className="btn btn-circle btn_color ">❮</a>
                <a href={`#slide${next}`} className="btn btn-circle btn_color ">❯</a>
            </div>

        </div>
    )
}

export default BannerItem