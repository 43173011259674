import React from 'react'
import Header from '../pages/shared/Header/Header'
import { Outlet } from 'react-router-dom'
import Footer from '../pages/shared/Footer/Footer'
import Products from '../pages/Products/Products'

const ProductLayout = () => {
  return (
    <div>
      <Header></Header>
      <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-4  gap-1 max-w-screen-xl	m-auto my-5">
        <div className="">
          <Products></Products>
        </div>
        <div className="col-span-3">
          <Outlet></Outlet>
        </div>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default ProductLayout;